/* eslint-disable react/no-danger */
import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import PageWrapper from 'Layouts/PageWrapper';
import SEO from 'Components/SEO';

export default ({ data }) => {
  const post = data.markdownRemark;
  const featuredImg = post?.frontmatter?.image?.childImageSharp.fluid;

  return (
    <PageWrapper>
      <SEO
        description={ post.excerpt }
        title={ post.frontmatter.title }
      />
      <div className="page__hero">
        <h1 className="page__hero__title">{ post.frontmatter.title }</h1>
        { !!featuredImg && (
          <Img fluid={ featuredImg } />
        ) }
      </div>

      <div className="page__content page__content--research" dangerouslySetInnerHTML={ { __html: post.html } } />
    </PageWrapper>
  );
};

export const query = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      excerpt
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 730) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
